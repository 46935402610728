<template>
  <div id="privacy">
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> PRIVACY POLICY </v-icon>
          </v-card-title>
          <v-card-text>Last updated on 01/12/2021</v-card-text>
          <v-card-text>
            SIA "LeadProm Media" (Matīsa iela, 61 - 31, Rīga, Latvia, LV-1009) Merchanto, "Us", "We", or "Our")
            understand that your privacy is important to you and are committed to being transparent about the
            technologies it uses.
            <br /><br />
            This Privacy Policy (“Privacy Policy”) describes how we collect and use the personal data you provide. It
            also describes the choices available to you regarding our use of your Personal Data and how you can access
            the information. We respect your privacy and we take protecting it seriously.
            <br /><br />
            This Privacy Policy should be read together with our Cookie Policy and our Terms of Use.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 1. DATA CONTROLLER </v-icon>
          </v-card-title>
          <v-card-text>
            1.1. SIA "LeadProm Media" (Matīsa iela, 61 - 31, Rīga, Latvia, LV-1009) is the data controller that is
            responsible for the personal data collected and used within the scope of this Privacy Policy.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 2. PRINCIPLES OF DATA PROCESSING </v-icon>
          </v-card-title>
          <v-card-text>
            2.1. According to Art. 5 of the EU General Data Protection Regulation (GDPR), we adhere to the following
            principles to protect your privacy:
            <ul>
              <li>
                2.1.1. <b>principle of lawfulness, fairness, and transparency</b> - we process personal data lawfully,
                fairly, and transparently concerning the data subject;
              </li>
              <li>
                2.1.2. <b>principle of purpose limitation</b> - we collected personal data for specified, explicit, and
                legitimate purposes and not further processed in a manner that is incompatible with those purposes;
              </li>
              <li>
                2.1.3. <b>principle of data minimization</b> - we collect adequate personal data, relevant and limited
                to what is necessary concerning the purposes for which we are processed personal data;
              </li>
              <li>
                2.1.4. <b>principle of data accuracy</b> - we accurate personal data and, where necessary, keep up to
                date. We take every reasonable step to ensure that inaccurate personal data, having regard to the
                purposes for which they are processed, are erased or rectified without delay;
              </li>
              <li>
                2.1.5. <b>principle of storage limitation</b> - we keep personal data in a form which permits
                identification of data subjects for no longer than is necessary for the purposes for which the personal
                data are processed;
              </li>
              <li>
                2.1.6. <b>principle of integrity and confidentiality</b> - we process personal data in a manner that
                ensures appropriate security of the personal data, including protection against unauthorized or unlawful
                processing and accidental loss, destruction, or damage, using appropriate technical or organizational
                measures.
              </li>
            </ul>
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 3. PERSONAL DATA THAT WE COLLECT AND PROCESS </v-icon>
          </v-card-title>
          <v-card-text>
            3.1. To fulfill our obligations on provision service for you by Merchanto and applicable Terms of Use, We
            are entitled to ask you to provide us with your Personal Data, including (but not limited):
            <ul>
              <li>3.1.1. First Name;</li>
              <li>3.1.2. Last name;</li>
              <li>3.1.3. E-mail address;</li>
              <li>3.1.4. Phone number;</li>
              <li>3.1.5. Company name;</li>
              <li>
                3.1.6. Company address;
                <ul>
                  <li>3.1.6.1. Street building number;</li>
                  <li>3.1.6.2. City;</li>
                  <li>3.1.6.3. ZIP Code;</li>
                  <li>3.1.6.4. Country;</li>
                </ul>
              </li>
              <li>3.1.7. AIM (contact in Messenger);</li>
              <li>3.1.8. Tax ID;</li>
            </ul>
            <br />
            3.2. Personal Data collected by or transmitted to the Merchanto in the course of interacting and operating
            the Website may include, without limitation, the following Personal Data:
            <ul>
              <li>
                3.2.1. Device information, which may include (but is not limited to) information about the computer or
                mobile device you use to access the Website, including the hardware model, operating system and version,
                the web browser you use, and other device identifiers;
              </li>
              <li>
                3.2.2. Server log information, which may include (but is not limited to) the date and time of visits,
                the pages viewed, your IP address, time spent at the Website, and the websites you visit just before and
                just after the Website;
              </li>
              <li>
                3.2.3. Information collected by cookies and other similar technologies. We and our Service providers use
                various technologies to collect information when you interact with the Merchanto, including cookies and
                other similar technologies. Cookies are small data files that are stored on your device when you visit a
                Website, which enables us to collect information about your device identifiers, IP address, web browsers
                used to visit the Website, pages or features viewed, time spent on the Website, and links clicked. Where
                required, you will be asked for consent to our use of cookies. To view more information on what cookies
                we use and how we use them, please review our separate Cookie Policy on the Website.
              </li>
            </ul>
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 4. LAWFUL BASIS OF PROCESSING </v-icon>
          </v-card-title>
          <v-card-text>
            4.1. Under Art. 6 of the GDPR, we only process personal data, where we have a lawful basis for doing so.
            <br /><br />
            4.2. The processing of personal data, described in p. 3.1. of this Privacy Policy is performed based on
            contract obligations (Art. 6 (1) lit. (b) GDPR) and is performed only for the purposes specified in this
            Privacy Policy - to meet your request concerning the service, to formalize the development of the
            contractual relationship, which may also include cooperation offers.
            <br /><br />
            4.3. The processing of personal data, described in p. 3.2.1. and 3.2.2. of this Privacy Policy is performed
            based on a legitimate interest (Art. 6 (1) lit. (f) GDPR) and is performed only for the purposes specified
            in this Privacy Policy - To contact you about, and conduct, surveys or polls you choose to take part in and
            to analyze the data collected for market research purposes;
            <br /><br />
            4.4. The processing of personal data, described in p. 3.2.3. of this Privacy Policy is performed based on
            consent (Art. 6 (1) lit. (a) GDPR) and is performed only for the purposes specified in this Privacy Policy -
            to provide a greater and better experience with Us and other related purposes, which is described in detail
            in Cookie Policy on this Website.
            <br /><br />
            4.5. The processing of personal data, that is performed only for the purpose specified in this Privacy
            Policy - for marketing and promotion of Merchanto or related products, including those of a third party’s
            products which are related to Merchanto is only performed based on your consent (Art. 6 (1) lit. (a) GDPR).
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 5. THE PURPOSE OF DATA PROCESSING </v-icon>
          </v-card-title>
          <v-card-text>
            5.1. We may use the information collected from you for a variety of purposes, primarily, relating to
            providing our Service and information about our services. We may also use the information for such other
            purposes as otherwise allowed by law. For example, we may use the information for the following purposes:
            <ul>
              <li>5.1.1. To meet your request concerning the services provided by the Merchanto;</li>
              <li>
                5.1.2. To detect, investigate, and prevent illegal activities or conduct that may violate the Terms of
                Use of Merchanto, Cookie Policy, and this Privacy Policy;
              </li>
              <li>5.1.3. To provide a greater and better experience with Merchanto;</li>
              <li>
                5.1.4. To formalize the development of the contractual relationship and fully deliver the service you
                are requesting and, likewise, obtain the information that will allow us to provide you with that service
                fully;
              </li>
              <li>
                5.1.5. To contact you about, and conduct, surveys or polls you choose to take part in and to analyze the
                data collected for market research purposes;
              </li>
              <li>
                5.1.6. To conduct internal research and development and to improve, test, and enhance the features and
                functions of Merchanto;
              </li>
              <li>
                5.1.7. Marketing and promotion of Merchanto or related products, including those of a third party’s
                products which are related to Merchanto (If you do not want us to use your data in this way, please let
                us know by contacting us at support@merchanto.org;
              </li>
              <li>
                5.1.8. To meet our internal and external audit requirements, including our information security
                obligations;
              </li>
              <li>5.1.9. Tracking usage information;</li>
              <li>
                5.1.10. Any other purpose as we determine, in our sole discretion, to be necessary or required to ensure
                the safety and/or integrity of our prospects, employees, third parties, public, and/or Merchanto, or to
                comply with requirements of any applicable law.
              </li>
            </ul>
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2">
              6. COMPLIANCE WITH THE GENERAL DATA PROTECTION REGULATION (GDPR)
            </v-icon>
          </v-card-title>
          <v-card-text>
            6.1. If you are located in the European Economic Area (EEA) privacy rights are granted and all processing of
            Personal Data is performed by regulations and rules following the Regulation (EU) 2016/679 of the European
            Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the
            processing of Personal Data and on the free movement of such data, known as the General Data Protection
            Regulation (“GDPR”).
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 7. YOUR RIGHTS AND CHOICES </v-icon>
          </v-card-title>
          <v-card-text>
            7.1. Subject to possible restrictions under national law, as a data subject under the GDPR, you may have
            certain rights regarding the Personal Data that we collect on you.
            <br /><br />
            7.2. Under the Chapter 3 of GDPR, rights of the data subjects include the following:
            <ul>
              <li>
                7.2.1. <b>Right to access.</b> You may contact us to get confirmation as to whether or not we are
                processing your personal data. When we process your personal data, we will inform You of what categories
                of personal data we process regarding You, the processing purposes, the categories of recipients to whom
                personal data have been or will be disclosed, and the envisaged storage period or criteria to determine
                that period;
              </li>
              <li>
                7.2.2. <b>Right to withdraw consent.</b> In case our processing is based on the consent granted, You may
                withdraw the consent at any time by contacting us or by using the functionalities of the Website. You
                can withdraw your consent at any time by replying to the email with your withdrawal and your Personal
                Data will be deleted in 48 hours. Withdrawing consent may lead to fewer possibilities to use the
                Website;
              </li>
              <li>
                7.2.3. <b>Right to object.</b> In case our processing is based on a legitimate interest to run, maintain
                and develop our business, You have the right to object at any time to our processing. We shall then no
                longer process your personal data unless for the provision of our services or if we demonstrate other
                compelling legitimate grounds for our processing that override your interests, rights, and freedoms or
                for legal claims. Notwithstanding any consent granted beforehand for direct marketing purposes, You have
                the right to prohibit us from using personal data for direct marketing purposes, by contacting us or by
                using the functionalities of the Website, or unsubscribe possibilities in connection with our direct
                marketing messages;
              </li>
              <li>
                7.2.4. <b>Right to restriction of the processing.</b> You have the right to obtain from us restriction
                of processing of your personal data, as foreseen by applicable data protection law, e.g. to allow our
                verification of the accuracy of personal data after you contest the accuracy or to prevent us from
                erasing personal data when personal data are no longer necessary for the purposes but still required for
                your legal claims or when our processing is unlawful. Restriction of processing may lead to fewer
                possibilities to use the Website;
              </li>
              <li>
                7.2.5. <b>Right to data portability.</b> You have the right to receive your personal data from us in a
                structured, commonly used, and machine-readable format and to independently transmit those data to a
                third party, in case our processing is based on your consent and carried out by automated means;
              </li>
              <li>
                7.2.6. <b>Right to complain.</b> You have the right to complain with any relevant Data Protection
                Authority, in particular in the Member State of your residence, place of work, or the place of
                processing your personal data. If You wish to exercise any of the aforementioned rights, please Contact
                Us via support@ppc.buzz. We request that in the first instance you contact Our Data Protection Officer
                at any time via support@ppc. We promise to promptly consider your request and to respond to you
                following the requirements of the Data Protection Act and GDPR. Complaints to the Latvian Data
                Protection Inspectorate should be made by using the appropriate forms provided in the Latvian Data
                Protection Inspectorate Website;
              </li>
              <li>
                7.2.7. <b>How to use these rights.</b> To exercise any of the above-mentioned rights, You should
                primarily use the functions offered by the Website. If such functions are however not sufficient for
                exercising such rights, You shall send us a letter or email to the address set out below under Privacy
                Policy, including the following information: name, address, phone number, email address, and a copy of a
                valid proof of identity. We may request additional information necessary to confirm your identity. We
                may reject requests that are unreasonably repetitive, excessive, or manifestly unfounded.
              </li>
            </ul>
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 8. DATA STORING AND DELETION </v-icon>
          </v-card-title>
          <v-card-text>
            8.1. We store your Personal Data for as long as needed to provide you with Merchanto. We may store Data
            longer, but only in a way that it cannot be tracked back to you. When Personal Data is no longer needed, we
            delete it using reasonable measures to protect the Data from unauthorized access or use.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 9. DATA SECURITY </v-icon>
          </v-card-title>
          <v-card-text>
            9.1. We care to ensure the security of personal data. We follow generally accepted industry standards to
            protect the information submitted to us, both during transmission and once we receive it. We maintain
            technical, physical, and administrative security measures to provide reasonable protection for your Personal
            Data.
            <br /><br />
            9.2. There is no 100% secure method of transmission over the Internet or method of electronic storage.
            Therefore, we cannot guarantee its absolute security.
            <br /><br />
            9.3. We never process any kind of sensitive data or criminal offense data. Also, we never undertake to
            profile personal data.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 10. APPLICATION OF THIS PRIVACY POLICY </v-icon>
          </v-card-title>
          <v-card-text>
            10.1. This Privacy Policy is applicable to the Website. Once redirected to another website or App, this
            Policy is no longer applicable.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 11. ACCEPTANCE OF THESE CONDITIONS </v-icon>
          </v-card-title>
          <v-card-text>
            11.1. We assume that all Visitors have carefully read this document and agree to its content. If one does
            not agree with this Privacy policy, they should not use this Website.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 12. CHANGES AND UPDATES TO OUR PRIVACY POLICY </v-icon>
          </v-card-title>
          <v-card-text>
            12.1. From time to time, we may update this Privacy Policy. We encourage You to periodically check back and
            review this Privacy Policy so that You always will know what data we collect, how we use it, and with whom
            we share it.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 13. CONTACT US! </v-icon>
          </v-card-title>
          <v-card-text> 13.1. If you have any questions please contact us at support@merchanto.org. </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Privacy.vue',
};
</script>

<style lang="scss" scoped>
#privacy {
  ul > li {
    list-style: none;
  }
}
</style>
